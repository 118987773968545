import { useRouter } from "next/router"
import { useEffect } from "react"
import { page } from "analytics"

export const usePageTracking = () => {
  const router = useRouter()
  useEffect(() => {
    page()
  }, [router.asPath])
}
