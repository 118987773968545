/* eslint-disable sentence-case/sentence-case */
import * as Sentry from "@sentry/nextjs"
import { LoggedInUser } from "react-current-user"
import { GA4_ID } from "@scripts/google-analytics"
import { UserType } from "@graph/types/globalTypes"

type HeartPayrolEvent = "login" | "sign_up" | "payroll_registration" | "account_created"

export const page = () => {
  if (typeof window === "undefined") {
    return
  }

  window.analytics?.page()
}

export const identify = (user?: LoggedInUser) => {
  if (typeof window === "undefined") {
    return
  }

  if (user) {
    window.analytics?.identify(user.email)

    const gtag = window.gtag
    if (gtag) {
      gtag("config", GA4_ID, {
        user_id: user.email,
      })
    }
    Sentry.setUser({ email: user.email })
  } else {
    reset()
  }
}

export const reset = () => {
  if (typeof window === "undefined") {
    return
  }

  window.analytics?.reset()
}

export const setSessionUrl = (sessionURL: string) => {
  Sentry.configureScope((scope) => {
    scope.setExtra("sessionURL", sessionURL)
  })
}

export const trackButtonClick = (clickTarget: string) => {
  if (typeof window === "undefined") {
    return
  }

  window.analytics?.track("Button Clicked", {
    clickTarget,
    path: window.location.pathname,
  })
}

export const trackQuestion = (questionText: string, responseText: string) => {
  window.analytics?.track("question", {
    questionText,
    responseText,
  })
}

export const trackSignup = () => track("sign_up")
export const trackAccountCreated = (accountType: UserType) => track("account_created", { accountType })
export const trackLogin = () => track("login")
export const trackPayrollRegistration = () => track("payroll_registration")

const track = (name: HeartPayrolEvent, metadata?: any) => {
  if (typeof window === "undefined") {
    return
  }

  const gtag = window.gtag

  if (gtag) {
    gtag("event", name, metadata)
  }

  window.analytics?.track(name, metadata)
}

declare global {
  type KeyValuePair = { [key: string]: string }

  interface Window {
    analytics?: {
      identify(userId: string, traits?: KeyValuePair): void
      identify(traits: KeyValuePair): void
      page(): void
      reset(): void
      track(eventName: string, properties?: KeyValuePair): void
    }
    gtag?(type: "event", eventName: string, metadata?: any): void
    gtag?(type: "config", measurementId: string, metadata?: any): void
  }
}
