import css from "@styled-system/css"
import Head from "next/head"
import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

const andesRoundedFont = `
  @font-face {
    font-family: "Andes Rounded"; 
    src: url("/fonts/Andes/8d386a2806627ca543abfe371cc8aa1a.eot");
    src: url("/fonts/Andes/8d386a2806627ca543abfe371cc8aa1a.eot?#iefix") format("embedded-opentype"), url("/fonts/Andes/8d386a2806627ca543abfe371cc8aa1a.woff2") format("woff2"), url("/fonts/Andes/8d386a2806627ca543abfe371cc8aa1a.woff") format("woff"), url("/fonts/Andes/8d386a2806627ca543abfe371cc8aa1a.ttf") format("truetype"), url("/fonts/Andes/8d386a2806627ca543abfe371cc8aa1a.svg#Andes Rounded") format("svg");
    font-display: swap;
  }
`

const nunitoFont = `
  @font-face {
    font-family: "Nunito"; 
    src: url("/fonts/Nunito-Light/a68b324dfb93d1025b9bc43b6ba48e43.eot"); 
    src: url("/fonts/Nunito-Light/a68b324dfb93d1025b9bc43b6ba48e43.eot?#iefix") format("embedded-opentype"), url("/fonts/Nunito-Light/a68b324dfb93d1025b9bc43b6ba48e43.woff2") format("woff2"), url("/fonts/Nunito-Light/a68b324dfb93d1025b9bc43b6ba48e43.woff") format("woff"), url("/fonts/Nunito-Light/a68b324dfb93d1025b9bc43b6ba48e43.ttf") format("truetype"), url("/fonts/Nunito-Light/a68b324dfb93d1025b9bc43b6ba48e43.svg#Nunito") format("svg"); 
    font-display: swap;
  } 
`

const GlobalCSS: any = createGlobalStyle`
  ${andesRoundedFont}
  ${nunitoFont}
  ${reset}
  ${css({
    "*": {
      boxSizing: "border-box",
    },
    body: {
      fontFamily: "base",
      color: "gray.text",
      lineHeight: "1.2",
    },
    strong: {
      fontWeight: "bold",
    },
    small: {
      fontSize: 1,
    },
    em: {
      fontStyle: "italic",
    },
    dfn: {
      fontWeight: "bold",
    },
    a: {
      color: "inherit",
      textDecoration: "underline",
      textDecorationThickness: "1px",
      ":hover": {
        textDecorationThickness: "1px",
      },
    },
    p: {
      lineHeight: "1.5",
    },
    /**
     * Lists
     */
    ul: {
      lineHeight: "1.2",
    },

    /**
     * Tables
     */
    table: {
      width: "100%",
    },
    thead: {
      borderBottom: "1px solid",
      borderColor: "primary.300",
      fontWeight: "bold",
      textAlign: "left",
    },
    "th,td": {
      padding: 2,
    },
    "tr:nth-child(even)": {
      "th,td": {
        backgroundColor: "gray.50",
      },
    },
    "td.empty-state": {
      backgroundColor: "warning.background",
    },
  })}
`

export const GlobalStyle = () => {
  return (
    <>
      <Head>
        <link
          rel="preload"
          href="/fonts/Nunito-Light/a68b324dfb93d1025b9bc43b6ba48e43.woff2"
          as="font"
          type="font/woff2"
        />
        <link rel="preload" href="/fonts/Andes/8d386a2806627ca543abfe371cc8aa1a.woff2" as="font" type="font/woff2" />
      </Head>
      <GlobalCSS />
    </>
  )
}

GlobalStyle.displayName = "GlobalStyle"
