import { ApolloClient, ApolloLink, concat, createHttpLink, InMemoryCache } from "@apollo/client"

type Extras = {
  headers?: any
}
const cacheOptions = {
  possibleTypes: {
    Account: ["Client", "Employee"],
  },
}

export const createBrowserClient = () => {
  const httpLink = createHttpLink({
    uri: "/api/graphql/hp",
    credentials: "same-origin",
  })

  return new ApolloClient({
    cache: new InMemoryCache(cacheOptions),
    link: httpLink,
  })
}

export const createServerClient = (extra: Extras = {}) => {
  const headersLink = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...extra.headers,
      },
    }))

    return forward(operation)
  })

  const httpLink = createHttpLink({
    uri: process.env.PRIVATE_C7R_GRAPHQL || "https://dev.heartpayroll.com/api/graphql/hp",
  })

  return new ApolloClient({
    cache: new InMemoryCache(cacheOptions),
    link: concat(headersLink, httpLink),
  })
}
