import Script from "next/script"

const IDS = {
  production: "G-38SPXZ6ZXL",
  development: "G-D8LP1FW5NP",
  test: "",
}

export const GA4_ID = IDS[process.env.NODE_ENV]

export const GoogleAnalyticsScript = () => {
  return (
    <>
      <Script id="ga" async strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${GA4_ID}`} />
      <Script
        id="ga-setup"
        async
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            if ('${GA4_ID}' && !window.location.host.startsWith('staging')) {
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA4_ID}', {
                page_path: window.location.pathname,
              });
            }
          `,
        }}
      />
    </>
  )
}
