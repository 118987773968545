import { ThemeProvider } from "styled-components"
import theme from "@ui/theme"
import { GlobalStyle } from "@ui/global-styles"
import { ApolloProvider } from "@apollo/client"
import { createBrowserClient } from "@graph/client"
import { useMemo } from "react"
import { CurrentUserProvider } from "react-current-user"
import { CurrentAdminProvider } from "react-current-admin"
import { identify } from "analytics"
import { usePageTracking } from "react-analytics"
import { AppProps } from "next/app"
import { FontAwesomeScript } from "@icons/font-awesome-script"
import Head from "next/head"
import { GoogleAnalyticsScript } from "@scripts/google-analytics"
import { SegmentScript } from "@scripts/segment"

function MyApp({ Component, pageProps }: AppProps) {
  const client = useMemo(() => createBrowserClient(), [])

  const disableAnalytics = !!pageProps.admin

  usePageTracking()
  return (
    // @ts-ignore
    <ThemeProvider theme={theme}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
      </Head>
      <FontAwesomeScript />
      <SegmentScript userId={pageProps.user?.email} disabled={disableAnalytics} />
      <GoogleAnalyticsScript />
      {/* @ts-ignore */}
      <GlobalStyle />
      <ApolloProvider client={client}>
        <CurrentAdminProvider admin={pageProps.admin}>
          <CurrentUserProvider
            user={pageProps.user}
            onIdentify={(user) => {
              identify(user)
            }}
          >
            <Component {...pageProps} />
          </CurrentUserProvider>
        </CurrentAdminProvider>
      </ApolloProvider>
    </ThemeProvider>
  )
}

export default MyApp
