import { createContext, FC, ReactNode, useContext, useState } from "react"

export type CurrentAdmin = {
  id: string
  email: string
  firstName: string
  lastName: string
}

// Context Shape
export type CurrentAdminContext = CurrentAdmin | undefined | null
const CurrentAdminContext = createContext<CurrentAdminContext>(null)

// Hook
export const useCurrentAdmin = () => {
  return useContext(CurrentAdminContext)
}

// Provider
export type CurrentAdminProviderProps = {
  children: ReactNode
  admin?: CurrentAdmin
}

export const CurrentAdminProvider: FC<CurrentAdminProviderProps> = (props) => {
  const [admin] = useState(props.admin)

  return <CurrentAdminContext.Provider value={admin}>{props.children}</CurrentAdminContext.Provider>
}

CurrentAdminProvider.displayName = "CurrentAdminProvider"
