export const theme = {
  breakpoints: ["768px"],
  fonts: {
    // eslint-disable-next-line sentence-case/sentence-case
    base: "Nunito, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif",
    brand: "Andes Rounded, Arial, sans-serif"
  },
  fontWeights: {
    regular: 400,
    bold: 700,
  },
  radii: {
    small: "4px",
    medium: "8px",
    large: "12px",
    circle: "50%",
  },
  shadows: {
    lowLight: "0 0 4px rgba(0, 0, 0, 0.12)",
    lowDark: "0 0 4px rgba(0, 0, 0, 0.24)",
    mediumLight: "0 0 8px rgba(0, 0, 0, 0.12)",
    mediumDark: "0 0 8px rgba(0, 0, 0, 0.24)",
    highDark: "0 0 12px rgba(0, 0, 0, 0.24)",
  },
  sizes: {
    page: {
      half: "450px",
      max: "900px",
      marketing: "1200px"
    },
  },
  /**
   * Starts at 4 and increases to the exponent of 1.5.
   */
  space: [4, 6, 8, 12, 16, 24, 32, 48, 64],

  /**
   * Colors are labeled by either the color name (e.g. gray)
   * or by the purpose (e.g. primary, success, error).
   *
   * Multiple shaeds of each color are provided and sub-labeled as numbers.
   *
   * 100 is the lightest color.
   * 900 is the darkest color
   */
  colors: {
    primary: {
      10: "hsl(180, 60%, 98%)",
      100: "hsl(180, 42%, 90%)",
      200: "hsl(180, 42%, 85%)",
      300: "hsl(178, 50%, 59%)",
    },
    success: {
      100: "hsl(152, 100%, 89%)",
      700: "hsl(163, 100%, 26%)",
      800: "hsl(162, 100%, 15%)",
      get background() {
        return theme.colors.success[100]
      },
      get borde() {
        return theme.colors.success[700]
      },
      get text() {
        return theme.colors.success[800]
      },
    },
    warning: {
      100: "hsl(40, 100%, 92%)",
      700: "hsl(49, 100%, 21%)",
      800: "hsl(48, 100%, 15%)",
      get background() {
        return theme.colors.warning[100]
      },
      get border() {
        return theme.colors.warning[700]
      },
      get text() {
        return theme.colors.warning[800]
      },
    },
    error: {
      100: "hsl(0, 100%, 97%)",
      700: "hsl(352, 87%, 37%)",
      800: "hsl(352, 90%, 28%)",
      get background() {
        return theme.colors.error[100]
      },
      get border() {
        return theme.colors.error[100]
      },
      get text() {
        return theme.colors.error[800]
      },
    },
    gray: {
      10: "hsl(0, 0%, 99%)",
      30: "hsl(0, 0%, 97%)",
      50: "hsl(0, 0%, 95%)",
      100: "hsl(240, 2%, 90%)",
      200: "hsl(240, 3%, 80%)",
      300: "hsl(240, 3%, 70%)",
      400: "hsl(240, 3%, 57%)",
      600: "hsl(240, 2%, 40%)",
      700: "hsl(240, 2%, 30%)",
      800: "hsl(240, 2%, 24%)",
      get heading() {
        return theme.colors.gray[800]
      },
      get background() {
        return theme.colors.gray[100]
      },
      get border() {
        return theme.colors.gray[200]
      },
      get space() {
        return theme.colors.gray[10]
      },
      get text() {
        return theme.colors.gray[800]
      },
    },
  },
} as const

export default theme
