import { UserType } from "@graph/types/globalTypes"
import { createContext, FC, ReactNode, useContext, useEffect, useState } from "react"

export type CurrentUser = {
  id: string
  type: UserType | null
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  dateOfBirth: string
  isConfirmed: boolean
  features: {
    id: string
  }
}

export type LoggedInUser = CurrentUser

// Context Shape
export type SetCurrentUser = (user?: CurrentUser) => void

export type CurrentUserContext = [CurrentUser | undefined, SetCurrentUser]
export type LoggedInUserContext = [LoggedInUser, SetCurrentUser]
const CurrentUserContext = createContext<CurrentUserContext>([undefined, () => {}])

// Hook
export const useCurrentUser = () => {
  return useContext(CurrentUserContext)
}

export const useLoggedInUser = (): LoggedInUserContext => {
  const [currentUser, setCurrentUser] = useCurrentUser()

  if (!currentUser) {
    throw new Error(
      "`useLoggedInuser` was used a situation where being logged in is not guaranteed. Try `useCurrentUser` instead."
    )
  }

  return [currentUser, setCurrentUser]
}

// Provider
export type CurrentUserProviderProps = {
  children: ReactNode
  user?: CurrentUser
  onIdentify(user?: CurrentUser): void
}

export const CurrentUserProvider: FC<CurrentUserProviderProps> = (props) => {
  const [user, setUserState] = useState(props.user)

  const setCurrentUser = (user?: CurrentUser) => {
    props.onIdentify(user)
    setUserState(user)
  }
  useEffect(() => {
    props.onIdentify(user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <CurrentUserContext.Provider value={[user, setCurrentUser]}>{props.children}</CurrentUserContext.Provider>
}

CurrentUserProvider.displayName = "CurrentUserProvider"
