import Script from "next/script"
import { useMemo } from "react"

const segmentWriteKey = () => {
  if (process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) {
    return process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY
  } else if (process.env.NODE_ENV === "development") {
    return "09htowcrL4JiFIKQhn0v79ISzVQa5bjq"
  }
  return ""
}

const segmentScriptBody = (WRITE_KEY: string, userId: string = "") => `
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${WRITE_KEY}";analytics.SNIPPET_VERSION="4.15.2";
  analytics.load("${WRITE_KEY}");
  var userId = "${userId}";
  if (userId) {
    analytics.identify(userId)
  }
  }}();
`

type SegmentScript = {
  userId?: string
  disabled: boolean
}

export const SegmentScript = ({ userId, disabled }: SegmentScript) => {
  const scriptBody = useMemo(
    () => {
      const WRITE_KEY = segmentWriteKey()
      if (!WRITE_KEY) return ""
      if (disabled) return ""
      return segmentScriptBody(WRITE_KEY, userId)
    },
    // This memo should never re-run. This is for initial identification only.
    // eslint-disable-next-line
    []
  )
  return (
    <Script id="segment-script" async strategy="afterInteractive" dangerouslySetInnerHTML={{ __html: scriptBody }} />
  )
}
